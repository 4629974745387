<script>

import sucursalSelector from '@/components/helexium/sucursalSelector.vue';
import historicoDeRegistros from './historicoDeRegistros.vue';
import escribirRegistro from './escribirRegistro.vue';

export default {
	name: 'fichaIndicadorePESV',
	components: {
		sucursalSelector,
		historicoDeRegistros,
		escribirRegistro
	},
	data() {
		return {
			sucursal: null,
			logs: [],
			isInRead: 1,
		}
	},
	methods: {
		setSucursal( sucursal ) {
			this.sucursal = sucursal
			this.$store.getters.fetchGet({ path: `PESVFichaIndicadores/sucursal/${sucursal.value}` })
				.then(response => {
					if (response.ok) {
						return response.json()
					}
				})
				.then(result => {
					this.logs = result
					this.selectedLog = this.logs[0]
				})
		}
	}
}
</script>

<template>
	<div class="container-fluid pt-md-4 ">
		<!-- <card class="p-3">
			<h2 class="mb-3">Matriz de indicadores PESV</h2>
			<sucursalSelector @getSucursal="setSucursal" />
			<div class="d-flex flex-reverse" style="width: 100%">
				<button v-if="isInRead" @click="isInRead = 0" class="btn btn-outline-success px-5 my-1" :disabled="!sucursal">Crear matriz</button>
				<button v-else @click="isInRead = 1" class="btn btn-outline-success px-5 my-1" :disabled="!sucursal">Ver historial</button>
			</div>
		</card> -->
		<div class="row pl-3">
			<card class="p-3 col-md">
				<h2 class="mb-3">Matriz de indicadores PESV</h2>
				<sucursalSelector @getSucursal="setSucursal" />
				
			</card>
			<div class="col-md pl-3 custom-bg-card ">
				<vs-button 
					primary
					floating
					gradient
					class="p-3  card-padding-sm" @click="isInRead = 0" v-if="isInRead">
					<div class="row">
						<div class="col-md-3 d-flex justify-content-center icon-ficha" style="position: relative; ">
							<i class="fa-solid fa-plus" style="font-size: 7rem;" ></i>						
						</div>
						
						<div  class="col-md-9 " >
							<div class="p-2" fade-in v-if="isInRead">
								<h2 class="text-white">Crear matriz indicadora</h2>
								<p class="text-white h4">
									La información ingresada en el formulario se guardará automáticamente al crear la matriz de indicadores.
								</p>
							</div>
							<div class="p-2" fade-in v-else>
								<h2 >Historico de registros</h2>
								<p class="text-white h4">
									Los datos se mostrarán según la sucursal seleccionada y la informacion ligada a la misma.
								</p>
							</div>
							<!-- <vs-button
							icon
							
							animation-type="vertical"
							success
							v-if="isInRead"
							@click="isInRead = 0"
							class=""
							:disabled="!sucursal">
							<i class="fas fa-plus pr-1" ></i> 
							Crear matriz
							<template #animate >
								<i class="fas fa-plus" ></i> 
							</template>
							</vs-button> -->
						<!-- <vs-button 
							v-else
							icon
							
							animation-type="vertical"
							primary
							block
							@click="isInRead = 1"
							class=""
							:disabled="!sucursal">
							<i class="fas fa-eye pr-1" ></i> 
							Ver historial
							<template #animate >
								<i class="fas fa-eye" ></i> 
							</template>
						</vs-button> -->

						</div>
						
					</div>
					<!-- <div class="d-flex flex-reverse" style="width: 100%">
						
					</div> -->
					
				</vs-button>
				<vs-button 
				danger
				gradient
				floating
					class="px-3 py-3 mt-2 card-padding-sm color-white" @click="isInRead = 1" v-else>
					<div class="row">
						<div  class="col-md-3 d-flex justify-content-center icon-ficha" style="position: relative; ">
							<i class="fa-solid fa-clipboard" style="font-size: 7rem;" ></i>																			
						</div>
						
						<div  class="col-md-9 " >
							
							<div class="p-2" fade-in >
								<h2 class="text-white" >Historico de registros</h2>
								<p class="text-white h4">
									Los datos se mostrarán según la sucursal seleccionada y la informacion ligada a la misma.
								</p>
							</div>
							<!-- <vs-button
							icon
							
							animation-type="vertical"
							success
							v-if="isInRead"
							@click="isInRead = 0"
							class=""
							:disabled="!sucursal">
							<i class="fas fa-plus pr-1" ></i> 
							Crear matriz
							<template #animate >
								<i class="fas fa-plus" ></i> 
							</template>
							</vs-button> -->
						<!-- <vs-button 
							v-else
							icon
							
							animation-type="vertical"
							primary
							block
							@click="isInRead = 1"
							class=""
							:disabled="!sucursal">
							<i class="fas fa-eye pr-1" ></i> 
							Ver historial
							<template #animate >
								<i class="fas fa-eye" ></i> 
							</template>
						</vs-button> -->

						</div>
						
					</div>
					<!-- <div class="d-flex flex-reverse" style="width: 100%">
						
					</div> -->
					
				</vs-button>
			</div>
		</div>
		<card class="p-3 " v-if="sucursal && logs.length > 0">
			<!-- TODO: Se deberían recargar los logs al escribir un registro -->
			<historicoDeRegistros :logs="logs" v-if="isInRead"/>
			<escribirRegistro :sucursal="sucursal"  v-else/>
		</card>
		<div class=""  v-else-if="sucursal && logs.length === 0">
			<card  class="p-md-5 row mx-0 card-bg-sm"  :style="(isInRead ==0 ? 'display: none' : 'none')">
				<div class="my-0 col-md-12 offset-md-1 py-0 ml-2 w-card-responsive">
					<vs-alert success    >
						<div class=" row ">
							
							<div class="col-md-2 p-0 m-0 d-flex align-items-center justify-content-center">
								<div class="   d-flex justify-content-start   ">
									<i class="fa-solid fa-lightbulb" style="font-size:8rem;"  ></i>
									
								</div>
							</div>
							<div class="col padding-top-sm pb-3 py-4 ">
								<p class="h2 text-success">Ingresa datos.</p>
								<p class="h4 text-success">Antes de proseguir, es fundamental generar una matriz indicadora que nos permita visualizar y organizar la información relevante de manera clara y concisa. Esta matriz indicadora será una herramienta invaluable para asegurarnos de que todos los datos necesarios estén correctamente identificados y disponibles para su análisis posterior.</p>
							</div>
						</div>
					</vs-alert>
				</div>
			</card>
			<!-- <p  class="h1">No hay datos disponibles</p> -->
			<card class="">
				<escribirRegistro class="p-3" :style="(isInRead ==1 ? 'display: none' : 'none')" v-if="sucursal && logs.length === 0" :sucursal="sucursal" />
			</card>
		</div>
		<card v-else class="py-md-5 px-2 row mx-0 card-bg-sm">
			<div class="my-0 col-md-12 offset-md-1 mx-0 py-0   w-card-responsive">
				<vs-alert danger    >
					<div class=" row px-0">
						
						<div class="col-md-2 p-0 m-0 d-flex align-items-center justify-content-center">
							<div class="   d-flex justify-content-start   ">
								<i class="fa-solid fa-triangle-exclamation" style="font-size:8rem;"></i>
								<!-- <i class="fa-solid fa-lightbulb" style="font-size:8rem;"  ></i>						 -->
							</div>
						</div>
						<div class="col padding-top-sm pb-3 py-4 ">
							<p class="h2 text-danger">No se ha seleccionado ninguna sucursal.</p>
							<p class="h4 text-danger">Para mostrar la información requerida, es necesario que elijas una sucursal de la lista disponible. Por favor, selecciona una sucursal antes de continuar.</p>
						</div>
					</div>
				</vs-alert>
			</div>
		</card>
		

		
		<!-- <card class="p-3" v-if="sucursal">
			
			<historicoDeRegistros :logs="logs" v-if="isInRead"/>
			<escribirRegistro :sucursal="sucursal"  v-else/>
		</card>
		<card class="p-3" v-else>
			
			<p class="h1">:( no hay datos</p>
		</card> -->
		
	</div>
</template>
<style>
.card-bg-sm{

}
.padding-top-sm{

}
.w-card-responsive{

}
.card-padding-sm{

}
.img-forma-2{
	width: 140px; display: block; margin: auto; transform:rotate(-10deg);
}
.forma-2{

	border-radius: 15px 100px 100px 15px;
	width: 100%;
	height: 100%;
	/* margin-right: -20px; */
/* position: absolute; */
								
				
}
.img-forma{
	width: 7.5vw;
	transform:rotate(10deg);
	padding-top: 20px;
	margin-left: 5px;
	position: absolute; 
}
.forma{

	border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
	width: 200px;
	height: 120px;
	margin-top: 10px;
	margin-left: 10px;
	/* position: absolute; */
									
					
}
.icon-ficha {
	animation: leftAndRight 2s ease-in-out infinite !important;
	}
	.custom-bg-icon-forma-2 {
	background: radial-gradient(circle, #ff94a7, #d70027);
	box-shadow: 0px -1px 33px -8px #d70027;
	-webkit-box-shadow: 0px -1px 33px -8px #d70027;
	-moz-box-shadow: 0px -1px 33px -8px #d70027;
	transition: 0.5s !important;
	
	
	}
	.custom-bg-icon-forma-3 {
	background: radial-gradient(circle, rgb(255, 218, 149),rgb(254, 114, 28));
	box-shadow: 0px -1px 33px -8px rgb(254, 114, 28);
	-webkit-box-shadow: 0px -1px 33px -8px rgb(254, 114, 28);
	-moz-box-shadow: 0px -1px 33px -8px rgb(254, 114, 28);
	transition: 0.5s !important;
	
	}
	
	.custom-bg-card{
		transition: 0.5s !important;
		
	}
	.custom-bg-card:hover{
	transition: 0.5s !important;

		.custom-bg-icon-forma-2{
			
			background: radial-gradient(circle, #ffa1b2, #ff002f);
			box-shadow: 0px -1px 60px -8px #ff002f;
			-webkit-box-shadow: 0px -1px 60px -8px #ff002f;
			-moz-box-shadow: 0px -1px 60px -8px #ff002f;
		}
		
	}
	@media (max-width: 767px) {
		.padding-top-sm{
			padding-top: 120px !important;
		}
		.content-page.c-payment-block {
			padding: 10px 0 0 !important;
		}
		.img-forma{
			width: 90px;
			transform:rotate(10deg);
			padding-top: 17px;
			position: absolute; 
		}
		.forma{

			border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
			width: 110px;
			height: 110px;
			/* position: absolute; */
											
							
		}
		.card-padding-sm{
			
			margin-left: 60px;
			margin-right: 60px;
		}
		.img-forma-2{
			margin-top: 25px;
			width: 250px;
			display: flex;
			/* margin: auto; */
			transform:rotate(-10deg);
			position: relative;
			;
			
			/* top: -500px; */
			/* margin-top: 50px; */
		}
		
		.forma-2{
			/* margin-left: 18px !important; */
			border-radius: 15px 15px 70% 70%;
			width: 100%;
			height: 200px;
			/* margin-right: -20px; */
			/* position: absolute; */
										
						
			}
		.w-card-responsive{
			width: 99%;
			margin-left: 15%;
		}
	}
	@media (max-width: 490px){
				.card-padding-sm{
					margin-left: 0px;
				margin-right: 0px;
				}
				.w-card-responsive{
					width: 100%;
					margin-left: 0%;
					
				}
				.card-bg-sm{
					background-color: transparent !important;
				}
			}
</style>

