<script>
import schemaFichaIndicadoresPESV from './fichaIndicadoresSchema.js'
import { mapGetters } from 'vuex';
import RenderSchema from '@/components/BVForm/RenderSchema.vue'

export default {
    name: "FichaIndicadoresHistoricoDeRegistros",
    components: {
        RenderSchema
    },
    props: {
        logs: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
			indexSelected: 0,
            schema: schemaFichaIndicadoresPESV
        
        }
    },
    methods: {
		mapDate( date ){
			return this.useMoment(date).split(" ")[0] 
		},
    },
    computed: {
		...mapGetters(['useMoment']),
		resumenSelectedLog() {
			let result = {}

			const mapSchema = schemaFichaIndicadoresPESV.map( element => {
				return {
					key: element.name,
					label: element.label
				}
			})

			if( this.selectedLog ) {
				mapSchema.forEach( element => {
					result[element.label] = this.selectedLog[element.key]
				})
			}
			return result
		},
        selectedLog() {
            this.indexSelected = this.indexSelected >= this.logs.length ? 0 : this.indexSelected
            return this.logs[this.indexSelected]
        }
    }
}

</script>
<template>
    <div>
        <div class="row">
            <div class="col-md-3 mr-1">
                <h3 class="mb-3">histórico de registros</h3>
            </div>
            <div class="col-md">
                <h5 class="mb-2 bg-dark card py-2 px-3">{{ `Matriz del: ${mapDate(selectedLog.fechaDeCreacion)}` }}</h5>
            </div>

        </div>
        <div class="row px-3">
            <card class="col-md pt-3 min-height bg-dark pb-3 pr-3 mr-0 over-flow-historico" style="">
                <h3 class="mb-2 ">Lista de selección</h3>
                <p v-if="!selectedLog">Debe seleccionar una sucursal</p>

                <!-- <div class="buttons btn-group">
                    <button v-for="(log, idx) in logs" :key="`${log.name}${idx}`"
                        :class="{ 'active': selectedLog == log }" @click="indexSelected = idx"
                        class="btn btn-primary btn-lg">
                        {{ mapDate(log.fechaDeCreacion) }}
                    </button>
                </div> -->
                <div class="pr-2">
                    <vs-button
                        block
                        icon
                         
                        animation-type="vertical"
                        primary
                        v-for="(log, idx) in logs"
                        :key="`${log.name}${idx}`"
                        :class="{ 'active': selectedLog == log }" @click="indexSelected = idx"
                        class="py-1">
                        <i class="fa-solid fa-calendar-days pr-2" ></i> 
                        {{ mapDate(log.fechaDeCreacion) }}
                        
                        <template #animate >
                        <i class="fa-solid fa-calendar-days pr-1" ></i> 
                            
                        </template>
                    </vs-button>
                    
                </div>
            </card>

            <div class="col col-sm-9 col-sm" v-if="selectedLog">
                <RenderSchema class="mt-0 pt-0" :schema="schema" :data="logs[indexSelected]"/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;
    border-radius: 11px;
    flex-direction: column;
}

.buttons>* {
    border-radius: 0;
}
.over-flow-historico{
    
    overflow-y: scroll; height: 52vh
}
@media (max-width: 767px) {

    .over-flow-historico{
    
        overflow-y: scroll; height: 32vh
    }
}
</style>
