<script>
import fichaIndicadoresSchema from './fichaIndicadoresSchema.js';
import BVForm from '@/components/BVForm/BVForm.vue';
import Swal from 'sweetalert2';

export default {
    name: 'datosPersonalesPostulacion',
    components: {
        BVForm
    },
    props: {
        sucursal: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            schemaFichaIndicadores: fichaIndicadoresSchema,
            sucursalId: this.sucursal.value,
        }
    },
    computed: {
        injectData() {
            return {
                sucursalId: this.sucursal.value
            }
        }
    },
    methods: {
        sendData(self) {
            
            self.loader = true
            this.$store.getters.fetchPost({ path: `PESVFichaIndicadores`, data: self.data })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error(response.statusText)
                })
                .then(() => {
                    self.schema.forEach(item => {
                        item.value = null
                    })

                    Swal.fire({
                        title: '¡Registro guardado!',
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                    })
                })
                .catch(() => {
                    Swal.fire({
                        title: '¡Error al guardar!',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                    })
                })
                .finally(() => {
                    self.loader = false
                })
        }
    }
}
</script>

<template>
    <div>
        <h3 class="mb-3">Crear matriz indicadora: {{ sucursal.descripcion }}</h3>
        <BVForm :schema="schemaFichaIndicadores" :injectData="injectData" :callBackSubmit="sendData" />
    </div>
</template>